import * as React from "react"

// markup
const PrivacyPage = () => {
  return (
    <main>
      <h1>SynTest Privacy Policy</h1>
      <br/>
      <p>We don't collect or store data, period. :)</p>
    </main>
  );
}

export default PrivacyPage